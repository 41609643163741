/* You can add global styles to this file, and also import other style files */
@import "~jsoneditor/dist/jsoneditor.min.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.blue-snackbar {
    text-align: center;
    background: #2196F3;
    color: white;
}

.red-snackbar {
    text-align: center;
    align-items: center;
    background: #F44336;
    color: white;
}

/* -------------------------------------------------- */
.display-flex { 
    display: flex;
}

.flex-1 { flex: 1; }

.flex-2 { flex: 2; }

.flex-3 { flex: 3; }

.flex-4 { flex: 4; }

.flex-5 { flex: 5; }

.flex-6 { flex: 6; }

[class*="flex-"] {
    padding-left: 10px;
    padding-right: 10px;
  }

[class*="flex-"]:first-child {
padding-left: 5;
}

[class*="flex-"]:nth-last-child(2) {
padding-right: 0;
}
@media screen and (max-width: 500px){
.display-flex  {
flex-direction: column;
align-content: space-around;
flex-wrap: wrap;

}
[class*="flex-"] {
    padding: 2px;
  }
  
}
